import React from "react";

import { List, Avatar } from "antd";
import { EyeOutlined, FileOutlined } from "@ant-design/icons";
import Button from "@mui/material/Button";
import useWindowDimensions from "../../../common/useWindowDimensions ";

const RadiologyList = ({ radiologyDetails, setReportModalVisible }) => {
  const { width } = useWindowDimensions();

  const generateUrl = (id) => {
    window.open(
      `https://viewer.stage.pred.health/viewer/?url=${encodeURIComponent(
        `https://stage.pred.health/api/dicom-viewer-json/?radiology=${Buffer.from(
          id.toString()
        ).toString("base64")}&api_key=yeT9uJzM4J8LETRQ`
      )}`,
      "_blank"
    );
  };

  return (
    <List
      itemLayout="horizontal"
      dataSource={radiologyDetails}
      renderItem={(item) => (
        <List.Item className={width > 414 ? "" : "mobile-view-list"}>
          <List.Item.Meta
            avatar={
              <Avatar
                className={width > 414 ? "" : "mobile-view"}
                src={
                  item?.images_document?.thumbnail
                    ? "https://www.clipartmax.com/png/middle/345-3454009_file-x-ray-the-noun-project-wikimedia-radiology-icon-png.png"
                    : "https://www.clipartmax.com/png/middle/345-3454009_file-x-ray-the-noun-project-wikimedia-radiology-icon-png.png"
                }
              />
            }
            title={<a href="https://ant.design">{item.title}</a>}
            description={
              item.has_report_text ? (
                <div className="button-container">
                  <Button
                    variant="contained"
                    endIcon={<EyeOutlined />}
                    className="image-btn"
                    onClick={() => generateUrl(item.id)}
                  >
                    View Images
                  </Button>
                  <Button
                    variant="contained"
                    endIcon={<FileOutlined />}
                    className="report-btn"
                    onClick={setReportModalVisible}
                  >
                    View Report
                  </Button>
                </div>
              ) : (
                <Button
                  variant="contained"
                  endIcon={<EyeOutlined />}
                  className="image-btn"
                  onClick={() => generateUrl(item.id)}
                >
                  View Image
                </Button>
              )
            }
          />
          <div className="report-content">
            <span className="uploaded-date">{item?.document_date_string}</span>
            <span className="uploaded-doc">
              <span>Uploaded By :- </span>
              {`${item?.images_added_by?.full_name} (Image)`}
              {item?.report_added_by ? (
                <span className="report-text">
                  {`${item?.report_added_by?.full_name} (Report)`}
                </span>
              ) : (
                <></>
              )}
            </span>
          </div>
        </List.Item>
      )}
    />
  );
};

export default RadiologyList;
