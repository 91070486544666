import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ABORTED, abortController, errorCustomizer } from '../../../../utils/fetchController'

let requestCouter = 0;
const counter = (n) => (requestCouter = Math.max(0, requestCouter + n),requestCouter)

export const getBedData = createAsyncThunk(
  "users/getBedData",
  async ({ token, locationId, from_date, to_date }, thunkAPI) => {
    counter(1);
    const signal = abortController('getBedData');
    try {
      const response = await fetch(
        `https://stage.pred.health/api/data-insights/hospital-location/admission/get-bed-occupancy-data/${locationId}/`,
        {
          method: "POST",
          credentials: "include",

          headers: {
            Accept: "application/json",
            Authorization: `JWT ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            from_date: from_date,
            to_date: to_date,
          }),
          signal
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return errorCustomizer(thunkAPI, e);
    }
  }
);

export const bedDataInformationSlice = createSlice({
  name: "bedDataInformationDetails",
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    reportsDetails: [],
    averageStay: "",
    admissionData: [],
    bedData: [],
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.bedData = [];
      return state;
    },
  },
  extraReducers: {
    [getBedData.fulfilled]: (state, { payload }) => {
      counter(-1);
      state.isFetching = false;
      state.isSuccess = true;
      state.bedData = payload;
      return state;
    },
    [getBedData.rejected]: (state, { payload }) => {
      state.isFetching = payload === ABORTED ? ABORTED : false;
      state.isError = true;
      state.errorMessage = payload?.message;
    },
    [getBedData.pending]: (state) => {
      state.isFetching = true;
    },
  },
});

export const { clearState } = bedDataInformationSlice.actions;

export const bedDataInformationSelector = (state) =>
  state.bedDataInformationDetails;
